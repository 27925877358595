<template>
  <div id="center">
    <div class="up">
      <div class="bg-color-black item" v-for="item in titleItem" :key="item.title">
        <p class="ml-3 colorBlue fw-b">{{ item.title }}</p>
        <div>
          <dv-digital-flop :config="item.number" style="width:1.25rem;height:.625rem;"/>
        </div>
      </div>
    </div>
    <div class="down">
      <div class="ranking bg-color-black">
        <span style="color:#5cd9e8">
          <icon name="align-left"></icon>
        </span>
        <span class="fs-xl text mx-2 mb-1" style="font-size: 14px;">使用单位数量</span>
        <dv-scroll-ranking-board :config="ranking1" style="height:2.75rem"/>
      </div>
      <div class="ranking bg-color-black">
        <span style="color:#5cd9e8">
          <icon name="align-left"></icon>
        </span>
        <span class="fs-xl text mx-2 mb-1" style="font-size: 14px;">维保单位数量</span>
        <dv-scroll-ranking-board :config="ranking" style="height:2.75rem"/>
      </div>
      <!--      <div class="percent">
              <div class="item bg-color-black">
                <span>今日工单完成率</span>
                <CenterChart :id="rate[0].id" :tips="rate[0].tips" :colorObj="rate[0].colorData" />
              </div>
              <div class="item bg-color-black">
                <span>今日工单满意度</span>
                <CenterChart :id="rate[1].id" :tips="rate[1].tips" :colorObj="rate[1].colorData" />
              </div>
              <div class="water">
                <dv-water-level-pond :config="water" style="height: 1.5rem" />
              </div>
            </div>-->
    </div>
  </div>
</template>

<script>
// import CenterChart from "@/components/echart/center/centerChartRate";

export default {
  data() {
    return {
      titleItem: [
        {
          title: "电梯总数量",
          number: {
            number: [31352],
            toFixed: 0,
            content: "{nt}"
          }
        },
        {
          title: "使用单位总数量",
          number: {
            number: [3073],
            toFixed: 0,
            content: "{nt}"
          }
        },
        {
          title: "维保单位总数量",
          number: {
            number: [405],
            toFixed: 0,
            content: "{nt}"
          }
        },
        {
          title: "维保人员总数量",
          number: {
            number: [448],
            toFixed: 0,
            content: "{nt}"
          }
        }/*,
        {
          title: "超期未检电梯总数量",
          number: {
            number: [106],
            toFixed: 0,
            content: "{nt}"
          }
        },
        {
          title: "超期人员总数量",
          number: {
            number: [100],
            toFixed: 0,
            content: "{nt}"
          }
        }*/
      ],
      ranking: {
        data: [
          {
            name: "赛罕区",
            value: 105
          },
          {
            name: "新城区",
            value: 84
          },
          {
            name: "回民区",
            value: 72
          },
          {
            name: "玉泉区",
            value: 62
          },
          {
            name: "清水河县",
            value: 19
          },
          {
            name: "和林格尔县",
            value: 18
          },
          {
            name: "武川县",
            value: 18
          },
          {
            name: "土默特左旗",
            value: 12
          },
          {
            name: "托克托县",
            value: 15
          }
        ],
        carousel: "single",
        unit: ""
      },
      ranking1: {
        data: [
          {
            name: "赛罕区",
            value: 660
          },
          {
            name: "新城区",
            value: 670
          },
          {
            name: "回民区",
            value: 322
          },
          {
            name: "玉泉区",
            value: 269
          },
          {
            name: "清水河县",
            value: 27
          },
          {
            name: "和林格尔县",
            value: 55
          },
          {
            name: "武川县",
            value: 33
          },
          {
            name: "土默特左旗",
            value: 37
          },
          {
            name: "托克托县",
            value: 74
          }
        ],
        carousel: "single",
        unit: ""
      },
      water: {
        data: [24, 45],
        shape: "roundRect",
        formatter: "{value}%",
        waveNum: 3
      },
      // 通过率和达标率的组件复用数据
      rate: [
        {
          id: "centerRate1",
          tips: 89,
          colorData: {
            textStyle: "#3fc0fb",
            series: {
              color: ["#00bcd44a", "transparent"],
              dataColor: {
                normal: "#03a9f4",
                shadowColor: "#97e2f5"
              }
            }
          }
        },
        {
          id: "centerRate2",
          tips: 95,
          colorData: {
            textStyle: "#67e0e3",
            series: {
              color: ["#faf3a378", "transparent"],
              dataColor: {
                normal: "#ff9800",
                shadowColor: "#fcebad"
              }
            }
          }
        }
      ]
    };
  },
  components: {
    // CenterChart
    // centerChart1,
    // centerChart2
  }
};
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;

  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .item {
      border-radius: 0.0625rem;
      padding-top: 0.2rem;
      margin-top: 0.1rem;
      width: 49%;
      height: 0.875rem;
    }
  }

  .down {
    padding: 0.07rem 0.05rem;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 3.1875rem;
    justify-content: space-between;

    .bg-color-black {
      border-radius: 0.0625rem;
    }

    .ranking {
      padding: 0.125rem;
      width: 59%;
    }

    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 50%;
        height: 1.5rem;

        span {
          margin-top: 0.0875rem;
          display: flex;
          justify-content: center;
        }
      }

      .water {
        width: 100%;
      }
    }
  }
}
</style>
