<template>
  <div id="centreRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-line"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2" style="font-size: 14px;">使用单位电梯情况</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board :config="config" style="width:6.975rem;height:4.28rem"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ["使用单位名称", "电梯数量"],
        data: [
          ["呼和浩特市公共交通总公司", "114"],
          ["内蒙古维多利商业（集团）有限公司", "86"],
          ["呼和浩特市居然之家家居建材市场有限责任公司", "80"],
          ["内蒙古金维多利物业商业管理有限公司", "68"],
          ["内蒙古金盛国际家居有限公司", "60"],
          ["呼和浩特铁路局呼和浩特车站", "57"],
          ["内蒙古星烨商业运营管理有限公司", "56"],
          ["呼和浩特市红星美凯龙世博家居广场有限责任公司", "50"],
          ["呼和浩特市居然之家家居建材市场有限责任公司玉泉分公司", "48"],
          ["内蒙古民族商场有限责任公司", "40"],
        ],
        rowNum: 7, //表格行数
        headerHeight: 35,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true,
        columnWidth: [10, 300, 200, 150],
        align: ["center"]
      }
    };
  },
  components: {},
  mounted() {
  },
  methods: {}
};
</script>

<style lang="scss">
#centreRight1 {
  padding: 0.2rem;
  height: 5.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;

  .bg-color-black {
    height: 4.8125rem;
    border-radius: 0.125rem;
  }

  .text {
    color: #c3cbde;
  }

  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
  }
}
</style>
