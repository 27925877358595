<template>
  <div>
    <Chart :cdata="cdata"/>
  </div>
</template>

<script>
import Chart from './chart.vue'

export default {
  data() {
    return {
      cdata: {
        category: [
          "回民区",
          "玉泉区",
          "新城区",
          "赛罕区",
          "托克托县",
          "清水河县",
          "武川县",
          "和林格尔县",
          "土默特左旗",
        ],
        lineData: [
          322,
          269,
          670,
          660,
          74,
          27,
          33,
          55,
          37
        ],
        barData: [
          2268,
          3015,
          5567,
          6356,
          240,
          119,
          54,
          239,
          106
        ],
        rateData: [
          72,
          62,
          84,
          105,
          15,
          19,
          18,
          18,
          12
        ],
        rateData1: [
          /*          "回民区",
        "玉泉区",
        "新城区",
        "赛罕区",
        "托克托县",
        "清水河县",
        "武川县",
        "和林格尔县",
        "土默特左旗",*/
          98,
          86,
          102,
          114,
          10,
          5,
          5,
          8,
          20
        ]
      }
    };
  },
  components: {
    Chart,
  },
  mounted() {
    this.setData();
  },
  methods: {
    // 根据自己的业务情况修改
    setData() {
      for (let i = 0; i < this.cdata.barData.length - 1; i++) {
        let rate = this.cdata.barData[i] / this.cdata.lineData[i];
        this.cdata.rateData.push(rate.toFixed(2));
      }
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
